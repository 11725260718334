import { AvailabilityAddition } from "./availability-addition";
import { AvailabilityRemoval } from "./availability-removal";
import { CalculateMarkup } from "./calculate-markup";
import { Camouflage } from "./camouflage";
import { Cart } from "./cart";
import { ExpandableMenu } from "./expandable";
import { RemoteForm } from "./form";
import { OpenInertModalOnClick, OpenModalOnClick, OpenModalOnLoad } from "./modal";
import { ResponsiveLayout } from "./responsive-layout";
import { Toaster } from "./toaster";

window.addEventListener('DOMContentLoaded', () => {
  OpenInertModalOnClick.bootstrap()
  OpenModalOnClick.bootstrap()
  OpenModalOnLoad.bootstrap()
  ResponsiveLayout.bootstrap()
  Toaster.bootstrap()
  Camouflage.bootstrap()

  bootstrap(document)
})

;[
  ['modal:open', '.js-modal-container'],
  ['remote-form:error', '.js-remote-form-container'],
].forEach(([event, selector]) => {
  document.addEventListener(event, () => {
    document.querySelectorAll<HTMLElement>(selector).forEach(element => {
      bootstrap(element)
    })
  })
})

function bootstrap(scope: Document | HTMLElement) {
  AvailabilityAddition.bootstrap(scope)
  AvailabilityRemoval.bootstrap(scope)
  Cart.bootstrap(scope)
  ExpandableMenu.bootstrap(scope)
  RemoteForm.bootstrap(scope)
  CalculateMarkup.bootstrap(scope)
}