import { bootstrapComponent, findRequiredElement } from "./utils";

export class CalculateMarkup {
  static bootstrap(scope: Document | HTMLElement): void {
    bootstrapComponent<HTMLFormElement>(scope, '.js-calculate-markup', element => {
      const control = new CalculateMarkup(element)
      control.attachEventListeners()
      control.render()
    })
  }

  element: HTMLFormElement;
  buyPriceElement: HTMLInputElement;
  sellPriceElement: HTMLInputElement;
  markupElement: HTMLInputElement

  constructor(element: HTMLFormElement) {
    this.element = element;
    this.buyPriceElement = findRequiredElement(element, '.js-buy-price')
    this.sellPriceElement = findRequiredElement(element, '.js-sell-price')
    this.markupElement = findRequiredElement(element, '.js-markup')
  }

  attachEventListeners(): void {
    this.buyPriceElement.addEventListener('input', this.render.bind(this));
    this.sellPriceElement.addEventListener('input', this.render.bind(this));
  }

  render() {
    const buyPrice = parseFloat(this.buyPriceElement.value)
    const sellPrice = parseFloat(this.sellPriceElement.value)

    let markup
    if (buyPrice && sellPrice) {
      markup = `${((sellPrice - buyPrice) / buyPrice * 100).toFixed(0)}%`
    } else {
      markup = '-'
    }

    this.markupElement.value = markup
  }
}