import { bootstrapComponent, findRequiredElement } from "./utils"

export class ResponsiveLayout {
  static bootstrap(): void {
    bootstrapComponent<HTMLFormElement>(document, '.js-responsive-layout', element => {
      const control = new ResponsiveLayout(element)
      control.attachEventListeners()
    })
  }

  element: HTMLElement
  openMenuElement: HTMLElement
  menuElement: HTMLElement
  overlayElement: HTMLElement
  closeMenuElement: HTMLElement

  constructor(element: HTMLFormElement) {
    this.element = element
    this.menuElement = findRequiredElement(element, '.js-responsive-layout-menu')
    this.overlayElement = findRequiredElement(element, '.js-responsive-layout-overlay')
    this.openMenuElement = findRequiredElement(element, '.js-open-responsive-menu')
    this.closeMenuElement = findRequiredElement(element, '.js-close-responsive-menu')
  }

  attachEventListeners(): void {
    this.openMenuElement.addEventListener('click', () => {
      this.menuElement.classList.remove('hidden')
      this.overlayElement.classList.remove('hidden')
    })

    this.closeMenuElement.addEventListener('click', () => {
      this.menuElement.classList.add('hidden')
      this.overlayElement.classList.add('hidden')
    })
  }
}