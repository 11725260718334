import { bootstrapComponent } from "./utils";

export class RemoteForm {
  static bootstrap(scope: Document | HTMLElement): void {
    bootstrapComponent<HTMLFormElement>(scope, '.js-remote-form', element => {
      const control = new RemoteForm(element)
      control.attachEventListeners()
    })
  }

  id: string;
  element: HTMLFormElement;

  constructor(element: HTMLFormElement) {
    this.id = Math.random().toString(36).substring(2)
    this.element = element;
    element.parentElement?.classList.add('js-remote-form-container')
  }

  attachEventListeners(): void {
    this.element.addEventListener('submit', this.handleSubmit);
  }

  handleSubmit = (event: Event) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const url = form.action;
    const method = form.method;
    const data = new FormData(form);

    fetch(url, { method, body: data })
      .then(response => {
        switch (response.status) {
          case 200:
            const redirect = response.headers.get('X-Remote-Form-Redirect')
            if(redirect) window.location.href = redirect
            break
          case 205:
            window.location.reload()
            break
          case 422:
            response.
              text().
              then((data: string) => ({type: 'html', data})).then(response => {
                if(response.type === 'html') {
                  const newForm = elementFromHtml(response.data)
                  const oldForm = event.target as HTMLFormElement
          
                  replaceElement(oldForm, newForm)
                  document.dispatchEvent(new CustomEvent('remote-form:error'))
                }
              })
            break
        }
      })
  }
}

function elementFromHtml<T extends HTMLElement>(html: string): T {
  const template = document.createElement('template')
  template.innerHTML = html
  return template.content.firstElementChild as T
}

function replaceElement(oldElement: HTMLElement, newElement: HTMLElement): void {
  oldElement.parentElement?.replaceChild(newElement, oldElement)
}