import { bootstrapComponent, findRequiredElement } from "./utils";

export class AvailabilityAddition {
  static bootstrap(scope: Document | HTMLElement): void {
    bootstrapComponent<HTMLFormElement>(scope, '.js-availability-addition', element => {
      const control = new AvailabilityAddition(element)
      control.attachEventListeners()
      control.render()
    })
  }

  element: HTMLFormElement;
  barcodeOrNameElement: HTMLInputElement;
  quantityElement: HTMLInputElement | null;

  constructor(element: HTMLFormElement) {
    this.element = element
    this.barcodeOrNameElement = findRequiredElement<HTMLInputElement>(element, '.js-barcode-or-name')
    this.quantityElement = document.querySelector('.js-quantity')
  }

  attachEventListeners(): void {
  }

  render(): void {
    if(this.quantityElement) {
      this.quantityElement.focus()
      this.quantityElement.select()
    } else {
      this.barcodeOrNameElement.focus()
      this.barcodeOrNameElement.select()
    }
  }
}