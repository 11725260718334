/*
  This function allows you to attach an event to an element that is
  still not part of the DOM or it is a child of another element.

  Usage:
  document.addEventListener('click', delegate('.js-not-present-in-dom', (event) => { ... }))
*/

export function delegate<T extends HTMLElement>(selector: string, handler: (event: Event, target: T) => void) {
  return function (event: Event) {
    const target = event.target as T | null
    if (!target) return

    if(target.matches(selector)) {
      handler(event, target)
    } else {
      const parentTarget = target.closest(selector) as T | null
      if(!parentTarget) return

      handler(event, parentTarget)
    }
  }
}

export function findRequiredElement<T extends HTMLElement>(
  rootElement: HTMLElement,
  selector: string,
  errorMessage?: string
): T {
  const element = rootElement.querySelector<T>(selector)
  if (!element)
    throw new Error(
      errorMessage ||
        `"${selector}" element cannot be found in <${rootElement.tagName.toLowerCase()} name="${
          rootElement.className
        }">`
    )
  return element
}

export function targetFromEvent<T extends HTMLElement>(event: Event): T {
  const target = event.target as T | null
  if (!target) throw new Error('Cannot find target')
  return target
}

export function bootstrapComponent<T extends HTMLElement>(
  scope: Document | HTMLElement,
  selector: string,
  handler: (element: T) => void
): void {
  scope.querySelectorAll<T>(selector).forEach(element => {
    if (!element.dataset.bootstraped) {
      element.dataset.bootstraped = selector
      handler(element)
    } else {
      const bootstrappedSelectors = element.dataset.bootstraped.split(',')
      if (!bootstrappedSelectors.includes(selector)) {
        element.dataset.bootstraped = [...bootstrappedSelectors, selector].join(',')
        handler(element)
      }
    }
  })
}
