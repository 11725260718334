import {createPopper, Instance, Placement} from '@popperjs/core'
import {bootstrapComponent, findRequiredElement} from './utils'

export class Expandable {
  wrapperElement: HTMLElement
  handleElement: HTMLElement
  containerElement: HTMLElement
  expanded: boolean
  disabled: () => boolean
  popper?: Instance
  placement?: Placement
  onExpand?: () => void
  onCollapse?: () => void
  containerElementRelocated: boolean

  constructor({
    wrapperElement,
    handleElement,
    containerElement,
    disabled,
    onExpand,
    onCollapse,
    placement
  }: {
    wrapperElement: HTMLElement
    handleElement: HTMLElement
    containerElement: HTMLElement
    disabled?: () => boolean
    onExpand?: () => void
    onCollapse?: () => void
    placement?: Placement
  }) {
    this.wrapperElement = wrapperElement
    this.handleElement = handleElement
    this.containerElement = containerElement
    this.containerElement.tabIndex = -1
    this.containerElement.classList.add('js-expandable-container')
    this.expanded = false
    this.disabled = disabled || (() => false)
    this.containerElementRelocated = false
    this.onExpand = onExpand
    this.onCollapse = onCollapse
    this.placement = placement

    if (this.containerElement.isConnected && !this.containerElement.classList.contains('hidden')) {
      throw new Error('Please, add hidden class to the container. This will prevent flicking.')
    }
  }

  onHandleMousedown(): void {
    if (this.disabled()) return

    if (this.expanded) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand(): void {
    this.relocateContainerElement()
    this.expanded = true

    this.popper = createPopper(this.handleElement, this.containerElement, {
      placement: this.placement || 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]
    })

    this.render()
    this.onExpand && this.onExpand()
  }

  collapse(): void {
    this.relocateContainerElement()
    this.expanded = false
    this.popper?.destroy()
    this.popper = undefined
    this.render()
    this.onCollapse && this.onCollapse()
  }

  onHandleKeydown(event: Event) {
    if (this.disabled()) return
    if (!isKeyboardEvent(event)) return

    if (event.code === 'Enter' || event.code === 'Space' || event.code === 'ArrowDown') {
      event.preventDefault()
      this.expand()
    }
  }

  onContainerKeydown(event: Event) {
    if (this.disabled()) return
    if (isKeyboardEvent(event) && event.code === 'Escape') {
      this.collapse()
    }
  }

  onBlur(event: Event) {
    if (this.disabled()) return
    if (!this.expanded) return
    if (!isFocusEvent(event)) return

    if (event.relatedTarget && event.relatedTarget === this.handleElement) return
    if (event.relatedTarget && isWithin(this.containerElement, event.relatedTarget as Element)) return
    if (document.activeElement && isWithin(this.containerElement, document.activeElement)) return

    this.collapse()
  }

  attachEventListeners() {
    this.handleElement.addEventListener('click', this.onHandleMousedown.bind(this))
    this.handleElement.addEventListener('keydown', this.onHandleKeydown.bind(this))
    this.containerElement.addEventListener('blur', this.onBlur.bind(this), true)
    this.containerElement.addEventListener('keydown', this.onContainerKeydown.bind(this))

    this.containerElement.querySelectorAll<HTMLElement>('.js-collapse').forEach(element => {
      element.addEventListener('click', this.onHandleMousedown.bind(this))
    })
  }

  relocateContainerElement(): void {
    if (this.containerElementRelocated) return

    if (this.containerElement.isConnected) {
      this.containerElement.remove()
      this.containerElement.classList.remove('hidden')
    }
    this.containerElementRelocated = true
  }

  render(): void {
    if (!this.containerElementRelocated) return

    if (this.expanded) {
      this.wrapperElement.classList.add('expanded')
      if (!this.containerElement.isConnected) {
        document.body.appendChild(this.containerElement)
        this.containerElement.focus()
      }
    } else {
      this.wrapperElement.classList.remove('expanded')
      this.containerElement.isConnected && this.containerElement.remove()
      this.handleElement.focus()
    }
  }
}

export class ExpandableMenu {
  static bootstrap(scope: Document | HTMLElement): void {
    bootstrapComponent(scope, '.js-expandable-menu', element => {
      const expandable = new ExpandableMenu(element)
      expandable.attachEventListeners()
    })
  }

  expandable: Expandable

  constructor(element: HTMLElement) {
    this.expandable = new Expandable({
      wrapperElement: element,
      handleElement: findRequiredElement(element, '.js-expandable-menu-handle'),
      containerElement: findRequiredElement(element, '.js-expandable-menu-container')
    })
  }

  attachEventListeners() {
    this.expandable.attachEventListeners()
  }
}

function isWithin(parent: Element, element: Element) {
  let current: Element | null = element

  while (current) {
    if (current === parent) return true
    current = current.parentElement
  }

  return false
}

function isKeyboardEvent(event: KeyboardEvent | Event): event is KeyboardEvent {
  return 'code' in event
}

function isFocusEvent(event: FocusEvent | Event): event is FocusEvent {
  return 'relatedTarget' in event
}
