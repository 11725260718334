import { bootstrapComponent, findRequiredElement } from "./utils"

export class Toaster {
  static bootstrap(): void {
    bootstrapComponent<HTMLFormElement>(document, '.js-toaster', element => {
      const control = new Toaster(element)
      control.attachEventListeners()
      control.startTimer()
    })
  }

  element: HTMLElement
  closeButton: HTMLButtonElement

  constructor(element: HTMLFormElement) {
    this.element = element
    this.closeButton = findRequiredElement(element, '.js-close')
  }

  attachEventListeners(): void {
    this.closeButton.addEventListener('click', () => {
      this.element.remove()
    })
  }

  startTimer(): void {
    setTimeout(() => {
      this.element.remove()
    }, 5000)
  }
}