import { bootstrapComponent } from "./utils"

export class Camouflage {
  static bootstrap(): void {
    bootstrapComponent<HTMLFormElement>(document, '.js-camouflage', element => {
      const control = new Camouflage(element)
      control.attachEventListeners()
      control.render()
    })
  }

  element: HTMLElement
  visible: boolean = false

  constructor(element: HTMLFormElement) {
    this.element = element
  }

  attachEventListeners(): void {
    document.addEventListener('keypress', (event) => {
      if(event.key === 'h') {
        this.visible = !this.visible
        this.render()
      }
    })
  }

  render(): void {
    if(this.visible) {
      this.element.classList.remove('camouflaged')
    } else {
      this.element.classList.add('camouflaged')
    }
  }
}