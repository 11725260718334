import {delegate} from './utils'

function openModalFromUrl(url: string, options: ModalOptions): void {
  fetch(url)
    .then(response => response.text())
    .then(html => {
      const domParser = new DOMParser()
      const parsedModal = domParser.parseFromString(html, 'text/html')
      const children = Array.from(parsedModal.body.children)

      new Modal({children, options}).open()
    })
    .catch(reason => {
      throw new Error(reason)
    })
}

export const OpenModalOnClick = {
  bootstrap: () => {
    document.addEventListener(
      'click',
      delegate<HTMLAnchorElement>('.js-url-modal', (event, target) => {
        event.preventDefault()

        const {modalSize} = target.dataset
        const url = target.href
        openModalFromUrl(url, {modalSize: modalSize as ModalSize})
      })
    )
  }
}

export const OpenInertModalOnClick = {
  bootstrap: () => {
    document.addEventListener(
      'click',
      delegate('.js-inert-modal', event => {
        event.preventDefault()

        const target = event.target as HTMLElement | null
        if (!target) return

        const {modalId, modalSize} = target.dataset
        if (!modalId) return

        const modal = document.querySelector(modalId)
        if (!modal) return

        const children = Array.from((modal.cloneNode(true) as Element).children)

        new Modal({children, options: {modalSize: modalSize as ModalSize}}).open()
      })
    )
  }
}

export const OpenModalOnLoad = {
  bootstrap: () => {
    const target = document.querySelector<HTMLTemplateElement>('template.js-modal-on-load')

    if (!target) return

    const {modalUrl, modalSize} = target.dataset

    if (modalUrl) {
      openModalFromUrl(modalUrl, {modalSize: modalSize as ModalSize})
    } else {
      const children = Array.from((target.content.cloneNode(true) as HTMLTemplateElement).children)

      new Modal({children, options: {modalSize: modalSize as ModalSize}}).open()
    }
  }
}

type ModalOptions = {
  modalSize?: ModalSize
  onClose?: () => void
}

type ModalSize = 'small' | 'medium' | 'large'

export class Modal {
  size: ModalSize
  children: Element[]
  modalOverlay: HTMLElement
  modal: HTMLElement
  visible: boolean
  onClose?: () => void

  constructor({children, options}: {children: Element[]; options?: ModalOptions}) {
    this.children = children
    this.size = options?.modalSize ?? 'small'
    this.onClose = options?.onClose
    this.visible = false

    this.modalOverlay = document.createElement('div')
    this.modalOverlay.className = 'modal-overlay'

    this.modal = document.createElement('div')
    this.modal.className = `modal modal-${this.size} js-modal-container`
    this.modal.append(...children)

    this.modalOverlay.append(this.modal)

    this.attachEventListeners()
  }

  attachEventListeners(): void {
    // this.modalOverlay.onclick = this.close.bind(this)
    this.modal.addEventListener('click', delegate('.js-close', (event) => {
      event.preventDefault()
      this.close()
    }))

    this.modal.onclick = e => {
      e.stopPropagation()
    }
    this.modal.onkeydown = event => {
      switch (event.key) {
        case 'Escape': {
          this.close()
          break
        }
        default: {
          break
        }
      }
    }
  }

  open(): void {
    this.visible = true

    this.render()
  }

  close(): void {
    this.visible = false

    this.onClose && this.onClose()

    this.render()
  }

  render(): void {
    if (!this.visible) {
      document.body.removeChild(this.modalOverlay)

      return
    }

    document.body.appendChild(this.modalOverlay)
    document.dispatchEvent(new CustomEvent('modal:open')) // TODO: Ensure that this is dispatched only once
  }
}
